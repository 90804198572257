export const loadScript = src => {
  const tag = document.createElement('script')

  tag.setAttribute('src', src)
  tag.setAttribute('type', 'text/javascript')
  document.body.appendChild(tag)
}

export const removeUniquePartOfFileName = fileName => {
  if (!fileName) return 'N/a'

  const start = fileName.lastIndexOf('_')
  const end = fileName.lastIndexOf('.')
  const specifiedPart = fileName.slice(start, end)

  return fileName.replace(specifiedPart, '')
}

export const generateId = () => {
  const uniqueId = Math.random().toString(36).substring(2)

  return uniqueId
}
