<template>
  <div>
    <div class="app-header1 header py-1 d-flex">
      <div class="container-fluid">
        <div class="d-flex">
          <router-link
            class="header-logo"
            to="/"
          >
            <img
              alt=""
              class="header-brand-img"
              src="../../assets/images/logo.png"
            >
            <span
              class="logo-project-name"
            >BACKLOG
              <span class="logo-company-name">Gameflex</span>
            </span>
          </router-link>
          <a
            aria-label="Hide Sidebar"
            class="app-sidebar__toggle"
            data-toggle="sidebar"
            href="#"
          >
            <div class="sidebar-toggle-relative-count" />
          </a>
          <span
            v-if="user.companyId"
            class="header-company-name mt-2 ml-5 d-lg-flex d-md-flex d-sm-none "
          >
            {{ user.company.name }}
          </span>
          <div class="d-flex order-lg-2 ml-auto md-auto ms-auto header-right">
            <button
              aria-controls="navbarSupportedContent-4"
              aria-expanded="false"
              aria-label="Toggle navigation"
              class="navbar-toggler navresponsive-toggler d-md-none"
              data-target="#navbarSupportedContent-4"
              data-toggle="collapse"
              href="#navbarSupportedContent-4"
              type="button"
            >
              <i class="fe fe-more-vertical header-icons navbar-toggler-icon" />
            </button>

            <div
              class="p-0 mb-0 navbar navbar-expand-lg responsive-navbar navbar-dark"
            >
              <div
                id="navbarSupportedContent-4"
                class="navbar-collapse collapse d-md-block"
              >
                <div class="d-flex flex-wrap justify-content-center">
                  <div class="dropdown">
                    <span
                      v-if="user.companyId"
                      class="header-company-name mt-2 mr-1 d-lg-none d-md-none"
                    >
                      {{ user.company.name }}
                    </span>
                  </div>
                  <div class="header-selects">
                    <f-select
                      v-if="
                        isRenderCompanySelect
                      "
                      v-model="selectedCompanyId"
                      class="mr-1"
                      :data-url="'/companies/withoutCount'"
                      :placeholder="$t('labels.companies')"
                      :select-first="true"
                      :settings="{ allowClear: false }"
                      @change="onChangeCompany"
                    />
                    <f-select
                      v-if="isRenderProjectSelect"
                      v-model="selectedProject"
                      :data-params="{ companyId: selectedCompanyId }"
                      :data-url="'/select/projects'"
                      :has-select-all="isSelectAll"
                      :placeholder="$t('labels.projects')"
                      :select-all-option-text="
                        isSelectAll ? $t('labels.all') : null
                      "
                      :settings="{ allowClear: false }"
                      @change="onChangeProject"
                    />
                  </div>
                  <div class="dropdown">
                    <a
                      class="nav-link pr-0 leading-none user-img"
                      data-toggle="dropdown"
                      href="#"
                    >
                      <img
                        alt="profile-img"
                        class="avatar avatar-md brround"
                        src="@/assets/images/default-profile-photo.jpg"
                      >
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                    >
                      <router-link
                        class="dropdown-item"
                        :to="{
                          name: 'settings',
                          params: {
                            companySlug: userCompanySlug,

                          }
                        }"
                      >
                        <i class="dropdown-icon icon icon-user" />
                        {{ $t('labels.settings') }}
                      </router-link>

                      <a
                        class="dropdown-item"
                        @click="onLogout"
                      >
                        <i class="dropdown-icon icon icon-power" />
                        {{ $t('labels.logout') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar menu-->
    <div
      class="app-sidebar__overlay"
      data-toggle="sidebar"
    />
    <aside class="app-sidebar doc-sidebar">
      <div class="app-sidebar__user clearfix">
        <div class="dropdown user-pro-body">
          <div>
            <img
              alt="user-img"
              class="avatar avatar-lg brround"
              src="../../assets/images/default-profile-photo.jpg"
            >
            <!-- <router-link :to="{ name: 'userProfile' }" class="profile-img">
              <span class="fa fa-pencil" aria-hidden="true"></span
            ></router-link> -->
          </div>
          <div class="user-info">
            <h2>{{ user.fullName }}</h2>
            <span>{{ $t(`labels.${user.role}`) }}</span>
          </div>
        </div>
      </div>

      <ul class="side-menu">
        <div
          v-for="menuItem in menuItems"
          :key="menuItem.name"
        >
          <li v-if="!menuItem.childNames && checkMenuItemPermissions(menuItem)">
            <router-link
              class="side-menu__item"
              :class="{ active: isMenuItemActive(menuItem.name) }"
              data-toggle="slide"
              :to="{
                name: `${menuItem.name}`,
                params: {
                  companySlug: menuItem.companySlug,
                  projectSlug: menuItem.projectSlug
                }
              }"
            >
              <i :class="`side-menu__icon fa fa-${menuItem.icon}`" />
              <span class="side-menu__label">{{
                $t(`labels.${menuItem.name}`)
              }}</span>
              <!-- <div
                v-if="
                  menuItem.name === 'announcements' &&
                    unreadAnnouncementCount > 0
                "
                class="relative-property-count"
              >
                <span class="nav-unread badge badge-danger badge-pill">{{
                  unreadAnnouncementCount
                }}</span>
              </div> -->
              <div
                v-if="
                  menuItem.name === 'fileRequestSearch' &&
                    waitingFileRequestCount > 0
                "
                class="relative-property-count"
              >
                <span class="nav-unread badge badge-danger badge-pill">{{
                  waitingFileRequestCount
                }}</span>
              </div>
              <div
                v-if="
                  menuItem.name === 'fileUserAssignment' && assignmentCount > 0
                "
                class="relative-property-count"
              >
                <span class="nav-unread badge badge-danger badge-pill">{{
                  assignmentCount
                }}</span>
              </div>
            </router-link>
          </li>

          <li
            v-else-if="checkMenuItemPermissions(menuItem)"
            :class="{
              slide: true,
              'is-expanded': hasActiveChild(menuItem.childNames)
            }"
          >
            <a
              class="side-menu__item"
              :class="{ active: isMenuItemActive(menuItem.name, true) }"
              data-toggle="slide"
              href="#"
            >
              <i :class="`side-menu__icon fa fa-${menuItem.icon}`" />
              <span class="side-menu__label">{{
                $t(`labels.${menuItem.name}`)
              }}</span>
              <i class="angle fa fa-angle-right" />
            </a>
            <ul class="slide-menu">
              <div
                v-for="(childName, index) in menuItem.childNames"
                :key="index"
              >
                <li>
                  <router-link
                    class="slide-item"
                    :class="{ active: isMenuItemActive(childName.name) }"
                    :to="{
                      name: `${childName.name}`,
                      params: childName.params
                    }"
                  >
                    <i :class="`side-menu__icon fa fa-${childName.icon}`" />
                    {{ $t(`labels.${childName.name}`) }}
                  </router-link>
                </li>
              </div>
            </ul>
          </li>
        </div>
      </ul>

      <div class="app-sidebar-footer">
        <!-- <router-link :to="{ name: `userProfile` }">
          <span class="fa fa-cog" aria-hidden="true"></span>
        </router-link> -->
        <a @click="onLogout">
          <span
            aria-hidden="true"
            class="fa fa-sign-in"
          />
        </a>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { userRole } from '@/data/enums'

export default {
  data() {
    return {
      toggleNavbar: false,
      company: [],
      project: {},
      isSelectAll: false,
      selectedProject: null,
      selectedMenuItemName: null
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'isAuthenticated',
      'selectedProjectId',
      'selectedCompanyId'
    ]),
    userCompanySlug() {
      return this.user?.company?.slug
    },
    isUserAdmin() {
      return this.user?.role === userRole.ADMIN
    },
    selectedProjectId: {
      get() {
        return this.$store.getters.selectedProjectId
      },
      set(value) {}
    },
    selectedCompanyId: {
      get() {
        return (
          this.$store.getters.selectedCompanyId || this.user?.company?.id || 0
        )
      },
      set() {}
    },
    menuCompanySlug() {
      if (this.isUserAdmin && this.$route.params.companySlug) {
        return this.$route.params.companySlug
      }

      return this.user.company?.slug
    },
    menuProjectSlug() {
      if (this.isUserAdmin && this.$route.params.projectSlug) {
        return this.$route.params.projectSlug
      } else if (this.isUserAdmin && !this.$route.params.projectSlug) {
        return null
      }

      return this.user.projects?.[0]?.slug
    },
    isCompanyProjectRoutes() {
      return !['companies', 'settings'].includes(this.$route.name)
    },
    isRenderCompanySelect() {
      return this.isUserAdmin &&
      this.isCompanyProjectRoutes
    },
    isRenderProjectSelect() {
      return ![
        'companies',
        'settings'
      ].includes(this.$route.name) && this.selectedCompanyId
    },
    menuItems() {
      return [
        {
          name: 'feature',
          childNames: [
            {
              name: 'pendingFeatures',
              icon: 'clipboard',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            },
            {
              name: 'completedFeatures',
              icon: 'check-circle',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            },
            {
              name: 'rejectedFeatures',
              icon: 'ban',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            }
          ],
          icon: 'files-o'
        },
        {
          name: 'bugs',
          childNames: [
            {
              name: 'pendingBugs',
              icon: 'exclamation-circle',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            },
            {
              name: 'resolvedBugs',
              icon: 'check-circle',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            },
            {
              name: 'rejectedBugs',
              icon: 'ban',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            }
          ],
          icon: 'times-circle-o'
        },
        {
          name: 'reports',
          icon: 'table',
          childNames: [
            {
              name: 'featureReport',
              icon: 'clipboard',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            },
          ],
          permissions: [userRole.ADMIN]
        },
        {
          name: 'management',
          childNames: [
            {
              name: 'deployments',
              icon: 'rocket',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            },
            {
              name: 'users',
              icon: 'users',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            },
            {
              name: 'companies',
              icon: 'building',
              params: {
                companySlug: this.menuCompanySlug,
                projectSlug: this.menuProjectSlug
              }
            }
          ],
          icon: 'cogs',
          permissions: [userRole.ADMIN]
        }
      ]
    }
  },
  async created() {
    if (this.isCompanyProjectRoutes) {
      await this.getCompanies()
      this.setProjectFromUrlBySlug()

      if (this.isUserAdmin) {
        await this.checkSelectedCompanySlug()
      }
    }
  },
  watch: {
    async company(value) {
      if (this.isCompanyProjectRoutes) {
        if (this.isUserAdmin) {
          this.isSelectAll = value.projects.length > 1
        }
      }
    },
    async '$route'() {
      if (this.isCompanyProjectRoutes) {
        if (this.user.role !== userRole.ADMIN) {
          this.setProjectFromUrlBySlug()
        }

        if (this.isUserAdmin) {
          await this.checkSelectedCompanySlug()
        }
      }
    },
    immediate: true,
  },

  methods: {
    ...mapActions(['setCompany', 'setProject']),
    setProjectFromUrlBySlug() {
      const projectSlug = this.$route.params.projectSlug;

      if (projectSlug) {
        this.setSelectedProjectBySlug(projectSlug);
      } else {
        this.setDefaultProject();
      }
    },

    setSelectedProjectBySlug(projectSlug) {
      const projects = this.isUserAdmin ? this.company.projects : this.user.projects;

      const foundProject = projects.find(project => project.slug === projectSlug);

      if (foundProject) {
        this.selectedProject = foundProject.id;
      } else {
        this.selectedProject = this.user.projects[0].id;
      }


      this.$store.dispatch('setProject', this.selectedProject);
    },

    setDefaultProject() {
      if (this.isUserAdmin) {
        this.selectedProject = '-999';
        this.isSelectAll = this.company.projects.length > 1;
        this.selectedProjectId = JSON.stringify(this.company.projects.map(project => project.id));
      } else {
        this.selectedProject = this.user.projects[0].id;
      }

      this.$store.dispatch('setProject', this.selectedProjectId);
    },
    getProject() {
      this.project =
        this.company?.projects?.find(
          project => project.id === this.selectedProject
        ) || this.user.projects.find(({ id }) => id === this.selectedProjectId)
    },
    async getCompanies() {
      if (this.isUserAdmin) {
        const companies = await this.$http.get('/companies/withoutCount')


        if (this.$route.params.companySlug !== this.company.slug) {
          this.company = companies.find(
            ({ slug }) => slug === this.$route.params.companySlug
          )
        } else {
          this.company = companies.find(
            ({ id }) => id === this.user.company?.id
          )
        }

        this.isSelectAll = this.company.projects.length > 1
      } else {
        this.company = this.user.company
      }

      this.$store.dispatch('setCompany', this.company.id)
    },
    onLogout() {
      this.$store.dispatch('logout')
    },
    hasActiveChild(childNames) {
      return childNames.find(childName => childName.name === this.$route.name)
    },
    checkMenuItemPermissions(menuItem) {
      if (!menuItem.permissions) return true

      if (!this.user || !this.user.role) {
        return false
      }

      return menuItem.permissions.includes(this.user.role)
    },
    isMenuItemActive(menuItemName, hasChild = false) {
      let hasSelectedChildItem = false

      if (hasChild) {
        const filteredMenuItems = this.menuItems.filter(
          menuItem =>
            menuItemName === menuItem.name &&
            menuItem.childNames.includes(this.$route.name)
        )

        hasSelectedChildItem = filteredMenuItems.length > 0
      }

      if (
        this.$route.name !== menuItemName &&
        !hasSelectedChildItem &&
        this.$route.meta.parentRoute === menuItemName
      ) {
        hasSelectedChildItem = true
      }

      return this.$route.name === menuItemName || hasSelectedChildItem
    },
    async onChangeCompany(selectedId) {
      if (!selectedId) {
        return
      }

      const companies = await this.$http.get('/companies/withoutCount')

      this.company = companies.find(({ id }) => id === parseInt(selectedId))


      this.setCompany(parseInt(selectedId))

      if (this.company.projects.length !== 0) {
        this.selectedProject = this.company.projects[0].id
        this.$store.dispatch('setProject', this.company.projects[0].id)
      }

      this.$router.push({
        params: {
          companySlug: this.company.slug,
          projectSlug:
            this.company.projects.length !== 0
              ? this.company.projects[0].slug
              : null
        }
      })
    },
    async onChangeProject(selectedId) {
      if (!selectedId) {
        return
      }

      if (selectedId === '-999') {
        let allProjectIds

        if (this.user.companyId) {
          allProjectIds = this.user.projects.map(project => project.id)
        } else {
          allProjectIds = this.company.projects.map(project => project.id)
        }

        this.$store.dispatch('setProject', JSON.stringify(allProjectIds))

        if (this.$route.params.projectSlug) {
          this.$router.push({ params: { projectSlug: null } })
        }

        return
      }

      const project = await this.$http.get(`/companies/${this.company.id}/projects`, {
        params: { projectId: selectedId }
      })

      if (this.$route.params.projectSlug !== project[0].slug) {
        this.$router.push({ params: { projectSlug: project[0].slug } })
      }

      this.$store.dispatch('setProject', project[0].id)
    },
    async checkSelectedCompanySlug() {
      const companies = await this.$http.get(`/companies/withoutCount`)

      const foundCompany = companies.find(
        company => company.slug === this.$route.params.companySlug
      )

      const foundProject = companies.find(
        company => company.projects.find(project => project.slug === this.$route.params.projectSlug)
      )

      if (!foundCompany) {
        this.company = companies[0]
        this.selectedCompanyId = companies[0].id
        this.selectedProject = companies[0].projects[0].id

        this.$store.dispatch('setCompany', companies[0].id)

        this.$store.dispatch('setProject', companies[0].projects[0].id)

        this.$router.replace({
          params: {
            companySlug: companies[0].slug,
            projectSlug: companies[0].projects[0].slug
          }
        })
      }

      if (this.selectedProject !== -999 && !foundProject) {
        this.selectedProject = companies[0].projects[0].id
        this.$store.dispatch('setProject', companies[0].projects[0].id)
      }
    }
  }
}
</script>

<style>
.header-logo {
  min-width: 178px;
  padding-left: 5px;
  padding-top: 2px;
}
.header-brand-img {
  width: 60px;
  height: 40px;
}
.logo-project-name {
  position: relative;
  font-size: 20px;
  color: #473d8e;
  top: 12px;
}
.header-company-name {
  height: 40px;
  text-align: center;
  display: flex;
  font-weight: bold;
  align-items: center;
  padding: 0px 5px;
  font-size: 1rem;
  border-radius: 3px;
  letter-spacing: 0.03em;
  min-width: 2.375rem;
}
.header-selects {
  display: inline-flex;
  height: 40px;
  padding-top: 4px;
}
.logo-company-name {
  position: absolute;
  font-size: 12px;
  left: 0px;
  bottom: 20px;
  color: #f79421;
}

.app-sidebar .side-menu li a.active {
  background: linear-gradient(
    -225deg,
    rgba(72, 1, 255, 0.9) 0,
    rgba(121, 24, 242, 0.9) 48%,
    rgba(172, 50, 228, 0.9) 100%
  );
  color: #fff !important;
}
@media (max-width: 767px) {
  .header-company-name {
    font-size: 0.8rem;
  }
  .navbar-collapse {
    position: fixed;
    left: 0;
    background: #fff;
    width: 100%;
    margin-top: 120px;
  }
  .select-group-mobile {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 425px) {
  .d-sm-none {
    display: none;
  }
  .header-company-name {
    font-size: 0.7rem;
  }
}
@media (max-width: 1024px) {
  .select2 {
    min-width: 100px;
  }
  .select2-dropdown.select2-dropdown--below {
    width: auto;
    min-width: 100px !important;
  }

  .select2-container--default .select2-selection--single {
    height: 37px;
    width: auto !important;
    position: relative;
    min-width: 100px !important;
    font-size: 12px;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    min-width: none;
    width: 40px;
    color: #fff;
    font-size: 1.3em;
    padding: 4px 12px;
    height: 27px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    font-size: 12px;
  }
}
</style>
