<template>
  <div class="app sidebar-mini">
    <div class="page">
      <div class="page-main">
        <Header />
        <div class="app-content my-3 my-md-5">
          <div class="side-app">
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './header.vue'
import Footer from './footer.vue'
import { loadScript } from '@/utils'

export default {
  components: {
    Header,
    Footer
  },
  mounted() {
    loadScript('/assets/js/main.js')
  }
}
</script>
