var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"app-header1 header py-1 d-flex"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"header-logo",attrs:{"to":"/"}},[_c('img',{staticClass:"header-brand-img",attrs:{"alt":"","src":require("../../assets/images/logo.png")}}),_c('span',{staticClass:"logo-project-name"},[_vm._v("BACKLOG "),_c('span',{staticClass:"logo-company-name"},[_vm._v("Gameflex")])])]),_vm._m(0),(_vm.user.companyId)?_c('span',{staticClass:"header-company-name mt-2 ml-5 d-lg-flex d-md-flex d-sm-none "},[_vm._v(" "+_vm._s(_vm.user.company.name)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex order-lg-2 ml-auto md-auto ms-auto header-right"},[_vm._m(1),_c('div',{staticClass:"p-0 mb-0 navbar navbar-expand-lg responsive-navbar navbar-dark"},[_c('div',{staticClass:"navbar-collapse collapse d-md-block",attrs:{"id":"navbarSupportedContent-4"}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-center"},[_c('div',{staticClass:"dropdown"},[(_vm.user.companyId)?_c('span',{staticClass:"header-company-name mt-2 mr-1 d-lg-none d-md-none"},[_vm._v(" "+_vm._s(_vm.user.company.name)+" ")]):_vm._e()]),_c('div',{staticClass:"header-selects"},[(
                      _vm.isRenderCompanySelect
                    )?_c('f-select',{staticClass:"mr-1",attrs:{"data-url":'/companies/withoutCount',"placeholder":_vm.$t('labels.companies'),"select-first":true,"settings":{ allowClear: false }},on:{"change":_vm.onChangeCompany},model:{value:(_vm.selectedCompanyId),callback:function ($$v) {_vm.selectedCompanyId=$$v},expression:"selectedCompanyId"}}):_vm._e(),(_vm.isRenderProjectSelect)?_c('f-select',{attrs:{"data-params":{ companyId: _vm.selectedCompanyId },"data-url":'/select/projects',"has-select-all":_vm.isSelectAll,"placeholder":_vm.$t('labels.projects'),"select-all-option-text":_vm.isSelectAll ? _vm.$t('labels.all') : null,"settings":{ allowClear: false }},on:{"change":_vm.onChangeProject},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}}):_vm._e()],1),_c('div',{staticClass:"dropdown"},[_vm._m(2),_c('div',{staticClass:"dropdown-menu dropdown-menu-right dropdown-menu-arrow"},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                        name: 'settings',
                        params: {
                          companySlug: _vm.userCompanySlug,

                        }
                      }}},[_c('i',{staticClass:"dropdown-icon icon icon-user"}),_vm._v(" "+_vm._s(_vm.$t('labels.settings'))+" ")]),_c('a',{staticClass:"dropdown-item",on:{"click":_vm.onLogout}},[_c('i',{staticClass:"dropdown-icon icon icon-power"}),_vm._v(" "+_vm._s(_vm.$t('labels.logout'))+" ")])],1)])])])])])],1)])]),_c('div',{staticClass:"app-sidebar__overlay",attrs:{"data-toggle":"sidebar"}}),_c('aside',{staticClass:"app-sidebar doc-sidebar"},[_c('div',{staticClass:"app-sidebar__user clearfix"},[_c('div',{staticClass:"dropdown user-pro-body"},[_vm._m(3),_c('div',{staticClass:"user-info"},[_c('h2',[_vm._v(_vm._s(_vm.user.fullName))]),_c('span',[_vm._v(_vm._s(_vm.$t(("labels." + (_vm.user.role)))))])])])]),_c('ul',{staticClass:"side-menu"},_vm._l((_vm.menuItems),function(menuItem){return _c('div',{key:menuItem.name},[(!menuItem.childNames && _vm.checkMenuItemPermissions(menuItem))?_c('li',[_c('router-link',{staticClass:"side-menu__item",class:{ active: _vm.isMenuItemActive(menuItem.name) },attrs:{"data-toggle":"slide","to":{
              name: ("" + (menuItem.name)),
              params: {
                companySlug: menuItem.companySlug,
                projectSlug: menuItem.projectSlug
              }
            }}},[_c('i',{class:("side-menu__icon fa fa-" + (menuItem.icon))}),_c('span',{staticClass:"side-menu__label"},[_vm._v(_vm._s(_vm.$t(("labels." + (menuItem.name)))))]),(
                menuItem.name === 'fileRequestSearch' &&
                  _vm.waitingFileRequestCount > 0
              )?_c('div',{staticClass:"relative-property-count"},[_c('span',{staticClass:"nav-unread badge badge-danger badge-pill"},[_vm._v(_vm._s(_vm.waitingFileRequestCount))])]):_vm._e(),(
                menuItem.name === 'fileUserAssignment' && _vm.assignmentCount > 0
              )?_c('div',{staticClass:"relative-property-count"},[_c('span',{staticClass:"nav-unread badge badge-danger badge-pill"},[_vm._v(_vm._s(_vm.assignmentCount))])]):_vm._e()])],1):(_vm.checkMenuItemPermissions(menuItem))?_c('li',{class:{
            slide: true,
            'is-expanded': _vm.hasActiveChild(menuItem.childNames)
          }},[_c('a',{staticClass:"side-menu__item",class:{ active: _vm.isMenuItemActive(menuItem.name, true) },attrs:{"data-toggle":"slide","href":"#"}},[_c('i',{class:("side-menu__icon fa fa-" + (menuItem.icon))}),_c('span',{staticClass:"side-menu__label"},[_vm._v(_vm._s(_vm.$t(("labels." + (menuItem.name)))))]),_c('i',{staticClass:"angle fa fa-angle-right"})]),_c('ul',{staticClass:"slide-menu"},_vm._l((menuItem.childNames),function(childName,index){return _c('div',{key:index},[_c('li',[_c('router-link',{staticClass:"slide-item",class:{ active: _vm.isMenuItemActive(childName.name) },attrs:{"to":{
                    name: ("" + (childName.name)),
                    params: childName.params
                  }}},[_c('i',{class:("side-menu__icon fa fa-" + (childName.icon))}),_vm._v(" "+_vm._s(_vm.$t(("labels." + (childName.name))))+" ")])],1)])}),0)]):_vm._e()])}),0),_c('div',{staticClass:"app-sidebar-footer"},[_c('a',{on:{"click":_vm.onLogout}},[_c('span',{staticClass:"fa fa-sign-in",attrs:{"aria-hidden":"true"}})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"app-sidebar__toggle",attrs:{"aria-label":"Hide Sidebar","data-toggle":"sidebar","href":"#"}},[_c('div',{staticClass:"sidebar-toggle-relative-count"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler navresponsive-toggler d-md-none",attrs:{"aria-controls":"navbarSupportedContent-4","aria-expanded":"false","aria-label":"Toggle navigation","data-target":"#navbarSupportedContent-4","data-toggle":"collapse","href":"#navbarSupportedContent-4","type":"button"}},[_c('i',{staticClass:"fe fe-more-vertical header-icons navbar-toggler-icon"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"nav-link pr-0 leading-none user-img",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('img',{staticClass:"avatar avatar-md brround",attrs:{"alt":"profile-img","src":require("@/assets/images/default-profile-photo.jpg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"avatar avatar-lg brround",attrs:{"alt":"user-img","src":require("../../assets/images/default-profile-photo.jpg")}})])}]

export { render, staticRenderFns }