export const userStatus = {
  NEW: 'new',
  ACTIVE: 'active',
  PASSIVE: 'passive',
  DELETED: 'deleted'
}

export const userRole = {
  ADMIN: 'admin',
  USER: 'user'
}

export const featureStatus = {
  WAITING: 'waiting',
  APPROVED: 'approved',
  IN_PROGRESS: 'inProgress',
  TESTING: 'testing',
  TEST_APPROVED: 'testApproved',
  REJECTED: 'rejected',
  DONE: 'done'
}

export const bugStatus = {
  WAITING: 'waiting',
  APPROVED: 'approved',
  IN_PROGRESS: 'inProgress',
  TESTING: 'testing',
  TEST_APPROVED: 'testApproved',
  REJECTED: 'rejected',
  DONE: 'done'
}

export const deploymentStatus = {
  201: 'startedMerging',
  204: 'noNewDevelopment',
  404: 'notFoundBaseBranch',
  409: 'conflict',
}
